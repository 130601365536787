import React, { useContext } from "react";

import { exitIcon, gearIcon } from "./Assets";
import BtnNormalCompact from "./BtnNormalCompact";
import { UserDataContext } from "./Level1";


function TopBar(props) {

  const userData = useContext(UserDataContext);
  return (
    <div className={`sticky w-full z-40 grid grid-cols-cust-3c ${props.productionMode ? "bg-primaryPale" : "bg-primary"} p-4 justify-center items-center sm:grid-rows-2 sm:grid-cols-cust-3k sm:p-2 sm:py-2`}>
      <div className={`w-full text-cust-user text-darkBackText sm:row-start-1 sm:row-end-2 sm:col-start-1 sm:col-end-3 sm:text`}>{props.displayName}</div>
      <div className={`w-full h-full sm:row-start-2 sm:row-end-3 sm:col-start-1 sm:col-end-4 sm:flex sm:items-center`} >
        <div className="mx-auto w-fit p-cust-1 bg-secondary rounded text-lightBackText text-center sm:px-4 sm:py-1 sm:flex sm:items-center">{props.viewName}</div>
      </div>
      <div className={`flex  justify-end sm:row-start-1 sm:row-end-2 sm:col-start-3 sm:col-end-4  sm:px-6 sm:gap-2`}>
 

   

        {/* <ButtonIcon
          section="Top Bar"
          Image={InfoIcon}
          colorInvert={true}
          clickHandler={() => props.btn1Click()}
        />

      <ButtonIcon
          section="Top Bar"
          Image={InfoIcon}
          colorInvert={true}
          clickHandler={() => props.btn1Click()}
        />

        <ButtonIcon
          section="Top Bar"
          Image={SignOutIcon}
          colorInvert={true}
          clickHandler={() => props.btn3Click()}
        /> */}

        {/* <BtnNormalCompact  spClass="sm:px-0 sm:hidden " toolTipLabel="Employee Portal Home" svgColorClass="f-color-white" imgWidth={35} img={infoIcon}   toolTipLabelPostion="bottom" clickHandler={() => props.infoClick()}/> */}
        <BtnNormalCompact spClass="sm:px-0"  toolTipLabel="Settings" toolTipLabelPostion="bottom"  svgColorClass="f-color-white" imgWidth={35} img={gearIcon} clickHandler={() => props.settingsClick()}/> 
        <BtnNormalCompact spClass="sm:px-0" toolTipLabel="Log Out" toolTipLabelPostion="top-left"  svgColorClass="f-color-white" imgWidth={35} img={exitIcon} clickHandler={() => props.logoutClick()}/>
 
      </div>
    </div>
  );
}

export default TopBar;

{
  /* <SupportIcon
onClick={props.btn1Function}
className="block h-10 w-10 text-darkBackText text-center"
/>
<CogIcon
onClick={props.btn2Function}
className="block h-10 w-10 text-darkBackText"
/>
<LogoutIcon
onClick={props.btn3Function}
className="block h-10 w-10 text-darkBackText"
/> */
}
